/**
 * This component will display the recommendation product rail
 *
 * @module views/components/RecommendationProductRail
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';

import ProductRail from '@ulta/components/ProductRail/ProductRail';


/**
 * Represents a RecommendationProductRail component
 *
 * @method
 * @param {RecommendationProductRailProps} props - React properties passed from composition
 * @returns RecommendationProductRail
 */
const RecommendationProductRail = function( props ){

  return (
    <div className='RecommendationProductRail'>
      <ProductRail { ...props } />
    </div>
  );

};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} deviceType='' - The default value of the deviceType is empty string.
 * @property {string} previousAccessibility='' - The default value of the previousAccessibility is empty string.
 * @property {string} nextAccessibility='' - The default value of the nextAccessibility is empty string.
 * @property {boolean} ssr=false - The default value for ssr is set to false.
 * @property {string} title='' - The default value of the title is empty string.
 * @property {string} countLabel='' - The default value of the count label is empty string.
 * @property {boolean} arrows=false - The default value for arrows is set to false.
 * @property {number} slidesToSlide=4 - The default value for slidesToSlide is set to 4.
 * @property {boolean} enableLoader=true - The default value for enableLoader is set to true by default.
 */
export const defaultProps = {
  deviceType: '',
  previousAccessibility: '',
  nextAccessibility: '',
  ssr: false,
  title: '',
  countLabel: '',
  arrows: false,
  slidesToSlide: 4,
  enableLoader: true
};

/**
 * Property type definitions
 * @typedef RecommendationProductRailProps
 * @type {object}
 * @property {string} deviceType - Sets the device type.
 * @property {string} previousAccessibility - Sets the aria label for previous button.
 * @property {string} nextAccessibility - Sets the aria label for next button.
 * @property {object} previousClickAction - This is the action object for the Next button.
 * @property {object} nextClickAction - This is the action object for the Previous button.
 * @property {boolean} ssr - Sets server side rendering.
 * @property {string} title - Sets the title for productRail list.
 * @property {string} countLabel - Sets the count label for productRail list.
 * @property {boolean} arrows - Sets whether to show or hide default arrows on carousel.
 * @property {number} slidesToSlide - Sets the number of slides to slide when cliked on next or previous button.
 * @property {Array.<{promotionalMessage: string, reviewCount: Number, rating: Number, brandName: string, productName: string, variantType: string, variantCount: string, badge: string, imageUrl: string, availability: array, price: object}>} products - List of products to loop over and display in carousel.
 * @property {object} viewAllAction - This is the action object with action label and url.
 * @property {string} carouselDetailsAccessibility - Value for aria label for carousel.
 * @property {string} carouselAccessibility - Value for aria label for product rail.
 * @property {string} carouselCardAccessibility - Value for the product card aria label.
 */
export const propTypes = {
  /** The prop that holds the device type. */
  deviceType: PropTypes.string,
  /** The prop that holds the aria label for previous button. */
  previousAccessibility: PropTypes.string,
  /** The prop that holds the aria label for next button. */
  nextAccessibility: PropTypes.string,
  /** The prop that decides to render on server side or not. */
  ssr: PropTypes.bool,
  /** The prop that holds title for the carousel. */
  title: PropTypes.string,
  /** The prop that holds the count label for the product list. */
  countLabel: PropTypes.string,
  /** The prop that decides to render default arrows on carousel. */
  arrows: PropTypes.bool,
  /** The prop that decides number of slides to slide when cliked on next or previous button. */
  slidesToSlide: PropTypes.number,
  /** This is the action with label and url. */
  viewAllAction: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string
  } ),
  /** The prop that holds the action for the next button. */
  nextClickAction: PropTypes.object,
  /** The prop that holds the action for the previous button. */
  previousClickAction: PropTypes.object,
  /** The prop that holds aria label for carousel. */
  carouselDetailsAccessibility: PropTypes.string,
  /** The prop that holds aria label for carousel. */
  carouselAccessibility: PropTypes.string,
  /** The prop that holds aria label for product card. */
  carouselCardAccessibility: PropTypes.string
};

RecommendationProductRail.propTypes = propTypes;
RecommendationProductRail.defaultProps = defaultProps;

export default RecommendationProductRail;
